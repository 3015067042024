import moment from "moment"
import TaskPage from "../../framework/components/page/TaskPage"
import CalendarValidation from "../../framework/components/taskValidation/CalendarValidation"
import MultipleChoiceValidation from "../../framework/components/taskValidation/MultipleChoiceValidation"
import AdjustmentValidation from "../../framework/components/taskValidation/AdjustmentValidation/AdjustmentValidation"
import { MemSustainedTask } from "./TaskConfig/MemSustainedTask"
import config from "../../utils/config"
import YesNoQuestion from "../../framework/components/taskValidation/YesNoValidation"
//import { confirmHrdDate } from "./TaskConfig/ConfirmHrdDate"

export const EmploymentTaskConfig = {
    default: {
        form: <TaskPage />
    },
    baseMonthlyEarnings: {
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if the employment has no base earnings, and status is on leave:
         * - event status is LTD or Maternity
         * - or has a `selfContribAccepted` event ("Self Contribution Accepted")
         * and employment status is eligible for self contribution: (key is `lpp` (Parental/Paternity leave) or `lpl` (Parental leave)
         * or `lpy` (Paternity leave) or `lcc` (Compassionate care/Adoption leave) or `lco` (Compassionate care leave)
         * or `lad` (Adoption Leave) or `lst` (Approved Medical Leave (ie: STD, CNESST))
         */
        validation: (employment, isLastEmployment) => {
            return employment.baseEarnings === 0 && employment.onLeaveNoBaseEarnings();
        },
        removeValidation: (employment) => {
            return employment.baseEarnings !== 0 || !employment.onLeaveNoBaseEarnings();
        }
    },
    selfContrib: {
        params: (employment, event) => [
            { key: 'effectiveDate', value: event.effDt }, 
            { key: 'endOfNextMonth', value: moment(event.effDt).add(1, 'months').endOf('month').format("YYYY-MM-DD") }, 
            { key: 'ets', value: event.ets }, 
            { key: 'mts', value: event.mts }
        ],
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if the employment has leave events without self events:
         * - has events eligibleForSelfContribution (eligible for self contribution) or selfContribAccepted (Self Contribution Accepted) or selfContribDeclined (Self Contribution Declined, or No self contributions – Offer expired)
         * - that are followed by events eligibleForSelfContribution and not selfContribAccepted (Self Contribution Accepted) and not selfContribDeclined (Self Contribution Declined, or No self contributions – Offer expired)
         */
        validation: (employment, isLastEmployment) => {
            return employment.events.getLeavesWithoutSelfEvents()
        },
        form: <MultipleChoiceValidation 
            choice={[
                {desc: 'Member accepted to self contribute', event: {code: 'asc'}},
                {desc: 'Member declined to self contribute', event: {code: 'dsc'}},
                {desc: 'No self contributions – Offer expired', event: {code: 'esc'}}
            ]} 
            header={'Confirm if the employee will make self-contributions for the leave with effective date: '}
            onYes={(employment, selectEvent, paramValue) => {
                employment.addEvent({
                    ...selectEvent,
                    ets: paramValue.ets.value + 10,
                    mts: paramValue.mts.value + 10,
                })
            }}
            isEmployment
            paramName="effectiveDate"
        />,
        showMultiple: true,
    },
    isStdOver15weeks: {
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if the last event status is LST (Approved Medical Leave (ie: STD, CNESST)) and is more than 15 weeks ago
         */
        validation: (employment, isLastEmployment) => {
            return employment.isStdOver15weeks()
        }
    },
    isMaternityOver18weeks: {
        params: (employment) =>  [
            {key: 'maternityEndDate', value: moment(employment.eventStatuses.last.effDt).add((employment.employer.plan.currentRates.maternityDuration) - 1, 'days').format('YYYY-MM-DD') },
            {key: 'maternityEndDateNextDay', value: moment(employment.eventStatuses.last.effDt).add((employment.employer.plan.currentRates.maternityDuration), 'days').format('YYYY-MM-DD') },
            {key: 'maternityEndDateNextMonth', value: moment(employment.eventStatuses.last.effDt).add((employment.employer.plan.currentRates.maternityDuration), 'days').add(1, 'months').endOf('month').format('YYYY-MM-DD') }
        ],
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if the employment status is Maternity and the number of days since its effective date is > the maternity duration in the employer's plan current rates
         * (meaning the Maternity status in the employment has exceeded the duration of the Maternity in the employer's plan)
         */
        validation: (employment, isLastEmployment) => {
            return employment.isMaternityOver18weeks()
        },
        formalDescription: "Maternity ends {maternityEndDate}. Eligible to self contribute during parental leave commencing {maternityEndDateNextDay}. Inform ASHAYES of employee’s decision. Offer expires {maternityEndDateNextMonth}"
    },
    hasOutstandingPackageFed: {
        params: (employment) => [{key: 'outstandingEndDate', value: moment(employment.participation.events.last.effDt).add(180, 'days').format('YYYY-MM-DD')}],
        alwaysShow: true,
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if it's the last employment and the employer's plan is federal, and the last event date is more than 180 days ago (~ 6 months), and the employment's participation has outstanding package over 6 months:
         * - the last participation event is `isPackageSent`: `a60oso` (Age 60: OSO - Outstanding Age60 Options. Package mailed by Mercer', 'Age60 Options. Package mailed by Mercer),
         * `l60oso` (LTD 60: OSO - Outstanding LTD60 Options. Package mailed by Mercer), `penOpo` (Retirement: OPO - Outstanding Pension Options. Options mailed by Mercer),
         * `trmPre` (Termination: OTR - Options package mailed by Mercer: entitled to refund), `trmPde` (Termination: OTD - Options package mailed by Mercer: entitled to deferred),
         * `trmOtn` (Termination: OTDN - Options package mailed by Mercer: entitled to a deferred with no additional benefit),
         * `trmPda` (Termination: OTDA - Options package mailed by Mercer: entitled to a deferred and additional benefit),
         * `deaOdo` (Death: ODO - Outstanding Deceased Options. Package mailed by Mercer)
         * - or the the last but one participation event is `isPackageSent`, and the last participation event is a `latest` (Latest follow up) event
         */
        validation: (employment, isLastEmployment) => {
            if(!employment.participation?.hasOutstandingPkgOver6Months) {
                console.warn('EmploymentTaskConfig hasOutstandingPackageFed validation: employment.participation.hasOutstandingPkgOver6Months is undefined/null, data might be corrupted');
            }
            const isLastEmploymentInParticipation = (typeof isLastEmployment === 'boolean' ? isLastEmployment : employment?.participation?.isLastEmploymentInParticipation?.());
            return isLastEmploymentInParticipation !== false && employment.employer.plan.isFederal() && employment.participation?.hasOutstandingPkgOver6Months?.(true);
        },
        removeValidation: (employment) => {
            if(!employment.participation?.hasOutstandingPkgOver6Months) {
                console.warn('EmploymentTaskConfig hasOutstandingPackageFed removeValidation: employment.participation.hasOutstandingPkgOver6Months is undefined/null, data might be corrupted');
            }
            return !employment.employer.plan.isFederal() && !employment.participation?.hasOutstandingPkgOver6Months?.(true);
        }
    },
    hasOutstandingPackageQue: {
        params: (employment) => [{key: 'outstandingEndDate', value: moment(employment.participation.events.last.effDt).add(180, 'days').format('YYYY-MM-DD')}],
        alwaysShow: true,
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if it's the last employment and the employer's plan is Quebec, and the last event date is more than 180 days ago (~ 6 months), and the employment's participation has outstanding package over 6 months:
         * - the last participation event is `isPackageSent`: `a60oso` (Age 60: OSO - Outstanding Age60 Options. Package mailed by Mercer', 'Age60 Options. Package mailed by Mercer),
         * `l60oso` (LTD 60: OSO - Outstanding LTD60 Options. Package mailed by Mercer), `penOpo` (Retirement: OPO - Outstanding Pension Options. Options mailed by Mercer),
         * `trmPre` (Termination: OTR - Options package mailed by Mercer: entitled to refund), `trmPde` (Termination: OTD - Options package mailed by Mercer: entitled to deferred),
         * `trmOtn` (Termination: OTDN - Options package mailed by Mercer: entitled to a deferred with no additional benefit),
         * `trmPda` (Termination: OTDA - Options package mailed by Mercer: entitled to a deferred and additional benefit),
         * `deaOdo` (Death: ODO - Outstanding Deceased Options. Package mailed by Mercer)
         * - or the the last but one participation event is `isPackageSent`, and the last participation event is a `latest` (Latest follow up) event
         */
        validation: (employment, isLastEmployment) => {
            if(!employment.participation?.hasOutstandingPkgOver6Months) {
                console.warn('EmploymentTaskConfig hasOutstandingPackageQue validation: employment.participation.hasOutstandingPkgOver6Months is undefined/null, data might be corrupted');
            }
            const isLastEmploymentInParticipation = (typeof isLastEmployment === 'boolean' ? isLastEmployment : employment?.participation?.isLastEmploymentInParticipation?.());
            return isLastEmploymentInParticipation !== false && employment.employer.plan.isQuebec() && employment.participation?.hasOutstandingPkgOver6Months?.(true);
        },
        removeValidation: (employment) => {
            if(!employment.participation?.hasOutstandingPkgOver6Months) {
                console.warn('EmploymentTaskConfig hasOutstandingPackageQue removeValidation: employment.participation.hasOutstandingPkgOver6Months is undefined/null, data might be corrupted');
            }
            return !employment.employer.plan.isQuebec() && !employment.participation?.hasOutstandingPkgOver6Months?.(true);
        }
    },
    hasUnclaimedBenefitQue: {
        params: (employment) => [{key: 'unclaimedBenefitDate', value: moment(employment.participation.events.last.effDt).format('YYYY-MM-DD')}],
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if:
         * - it's the last employment and the employer's plan is Quebec
         * - and the last participation event is `isPackageSent`: `a60oso` (Age 60: OSO - Outstanding Age60 Options. Package mailed by Mercer', 'Age60 Options. Package mailed by Mercer),
         * `l60oso` (LTD 60: OSO - Outstanding LTD60 Options. Package mailed by Mercer), `penOpo` (Retirement: OPO - Outstanding Pension Options. Options mailed by Mercer),
         * `trmPre` (Termination: OTR - Options package mailed by Mercer: entitled to refund), `trmPde` (Termination: OTD - Options package mailed by Mercer: entitled to deferred),
         * `trmOtn` (Termination: OTDN - Options package mailed by Mercer: entitled to a deferred with no additional benefit),
         * `trmPda` (Termination: OTDA - Options package mailed by Mercer: entitled to a deferred and additional benefit),
         * `deaOdo` (Death: ODO - Outstanding Deceased Options. Package mailed by Mercer)
         * - or the the last but one participation event is `isPackageSent`, and the last participation event is a `latest` (Latest follow up) event
         */
        validation: (employment, isLastEmployment) => {
            if(!employment.participation?.isPackageSent) {
                console.warn('EmploymentTaskConfig hasUnclaimedBenefitQue validation: employment.participation.isPackageSent() is undefined/null, data might be corrupted');
            }
            const isLastEmploymentInParticipation = (typeof isLastEmployment === 'boolean' ? isLastEmployment : employment?.participation?.isLastEmploymentInParticipation?.());
            return isLastEmploymentInParticipation !== false && employment.employer.plan.isQuebec() && (employment.participation?.isPackageSent?.() || employment.participation?.isPackageSentAndLatestFollowUp?.());
        },
        alwaysShow: true,
        // removeValidation: (employment) => {
        //     if(!employment.participation?.hasOutstandingPkgOver6Months) {
        //         console.warn('EmploymentTaskConfig hasUnclaimedBenefitQue removeValidation: employment.participation.hasOutstandingPkgOver6Months is undefined/null, data might be corrupted');
        //     }
        //     console.log('test:', !employment.participation?.hasOutstandingPkgOver6Months?.())
        //     return !employment.employer.plan.isQuebec() && !employment.participation?.hasOutstandingPkgOver6Months?.();
        // }
    },
    signTermOpt: {
        params: (employment) => {return [{key: 'firedQuitDate', value: moment(employment.eventStatuses.last.effDt).format('YYYY-MM-DD')}]},
        alwaysShow: true,
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if it's the last employment and the fired/quit package is sent:
         * - the last status event is Fired/Quit: `tcl` (Employment Closed: events `tnf` "Employment Closed - No Earnings Reported" or `tnc` "Employment Closed - No Earnings Reported for more than one calendar year" or `tclCan` "Employment Closed - Eligibility period has expired")
         * or `tfq` (Fired/Quit: events `tfq` "Fired/Quit") or `trf` (Transferred Out: events `tro` "TRO – Transferred-Out" or `tsw` "SWO - Switched-Out") or `tex` (Leave Expired: events `tex` "Leave Expired")
         * - and the last participation event is "package sent":
         * `a60oso` (Age 60: OSO - Outstanding Age60 Options. Package mailed by Mercer), `l60oso` (LTD 60: OSO - Outstanding LTD60 Options. Package mailed by Mercer),
         * `penOpo` (Retirement: OPO - Outstanding Pension Options. Options mailed by Mercer), `trmPre` (Termination: OTR - Options package mailed by Mercer: entitled to refund),
         * `trmPde` (Termination: OTD - Options package mailed by Mercer: entitled to deferred), `trmOtn` (Termination: OTDN - Options package mailed by Mercer: entitled to a deferred with no additional benefit),
         * `trmPda` (Termination: OTDA - Options package mailed by Mercer: entitled to a deferred and additional benefit), `deaOdo` (Death: ODO - Outstanding Deceased Options. Package mailed by Mercer)
         * - or the `isPackageSent` participation event is the last but one, followed by a `latest` ("Latest follow up") participation event.
         */
        validation: (employment, isLastEmployment) => {
            const isLastEmploymentInParticipation = (typeof isLastEmployment === 'boolean' ? isLastEmployment : employment?.participation?.isLastEmploymentInParticipation?.());
            return isLastEmploymentInParticipation !== false && employment.isFiredQuitPackageSent(true)
        }
    },
    signPenOpt: {
        params: (employment) => [{key: 'retiredDate', value: moment(employment.eventStatuses.last.effDt).format('YYYY-MM-DD')}],
        alwaysShow: true,
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if it's the last employment and the last status event is `trd` (Retired) and the last participation event is "package sent":
         * `a60oso` (Age 60: OSO - Outstanding Age60 Options. Package mailed by Mercer), `l60oso` (LTD 60: OSO - Outstanding LTD60 Options. Package mailed by Mercer),
         * `penOpo` (Retirement: OPO - Outstanding Pension Options. Options mailed by Mercer), `trmPre` (Termination: OTR - Options package mailed by Mercer: entitled to refund),
         * `trmPde` (Termination: OTD - Options package mailed by Mercer: entitled to deferred), `trmOtn` (Termination: OTDN - Options package mailed by Mercer: entitled to a deferred with no additional benefit),
         * `trmPda` (Termination: OTDA - Options package mailed by Mercer: entitled to a deferred and additional benefit), `deaOdo` (Death: ODO - Outstanding Deceased Options. Package mailed by Mercer)
         */
        validation: (employment, isLastEmployment) => {
            const isLastEmploymentInParticipation = (typeof isLastEmployment === 'boolean' ? isLastEmployment : employment?.participation?.isLastEmploymentInParticipation?.());
            return isLastEmploymentInParticipation !== false && employment.isRetiredPackageSent()
        }
    },
    onLeaveOverOneYear: {
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if it's the last employment and is on leave over one year:
         * - the last event status is On Leave and is more than a year ago
         * - and participation is not active,
         * - and the last participation events code is not `a60Nop` (NOP - Active60 Declaration received: continue accruing)
         */
        validation: (employment, isLastEmployment) => {
            const isLastEmploymentInParticipation = (typeof isLastEmployment === 'boolean' ? isLastEmployment : employment?.participation?.isLastEmploymentInParticipation?.());
            return isLastEmploymentInParticipation !== false && employment.isOnLeaveOverOneYear()
        }
    },
    confirmFrstDay: {
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if the participation has 2 or more employments, and the employment is still eligible:
         * - the employment's participation has an Eligibility end date (date of last event with code `metEligDate` (ELIG - Meets eligibility on this date))
         * - and the employment's hired date (effective date of the event `hrd` "Hired" or `hrdRem` "Hired through remittance") is before the participation's eligibility end date (1 year after the last eligibility event date)
         * - and the participation status is `ELI` (Eligible)
         */
        validation: (employment, isLastEmployment) => {
            const pp = employment.participation;
            if(pp?.employments?.length === undefined || pp?.employments?.length === null) {
                console.warn('EmploymentTaskConfig confirmFrstDay validation: pp.employments.length is undefined/null, data might be corrupted');
            }
            if(typeof pp?.employments?.length === 'number' && pp.employments.length < 2) return false;
            return employment.isStillEligible();
        },
        form: <CalendarValidation 
            dateLabel='Join Date' 
            taskSave={(participation, joinDt, comment) => 
                participation.addEvent({code: 'metElig', ets: moment(joinDt).valueOf(), cmt: comment})
            } 
            message='Enter the first day of work'
        />
    },
    deemedCheck: {
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if the employment has a `cDmd` (Verify deemed calculations) event.
         * This event is added if in the previous year or current year, the difference has earnings or deemed hours or contributions.
         * The difference comes from a method that verifies deemed amounts for closed periods of the current year and creates deemed adjustments if there's missing deemed amounts.
         * It returns the deemed earnings/hours/contributions amounts (actual, expected, difference) and adjustments created from these amounts.
         */
        validation: (employment, isLastEmployment) => {
            return employment.events.find(e => e.config.isDeemedCheck);
        },
        form: <AdjustmentValidation title="Deemed Adjustment" isEmployment />,
        formalDescription: " ",
    },
    memStask: {
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if:
         * - it's the last employment
         * - and the employment hired date (effective date of the event `hrd` "Hired" or `hrdRem` "Hired through remittance") is not before the 2023 cutoff (2023-01-01)
         * - and the participation status is active (`ACT` "Enrolled")
         * - and the participation status is not "pending but not close": not: participation has a pending event `penDea` (Pending - Deceased) or `penTrm` (Pending - Terminated) or `penPnr` (Pending - Pensioner), and participation status is not close: not `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled)
         * - and it's not the first employment in the participation
         * - and the employment does not have a `memSusEmailSent` event: `memS` (Membership Sustained notice has been sent to employee and employer)
         * - and the employment has a `isMultipleEmployer` event: `tro` (TRO – Transferred-Out) with status `trf` (Transferred Out), or `tsw` (SWO - Switched-Out) with status `trf` (Transferred Out), or `swi` (SWI - Switched-In), or `mer` (MER – Working at Multiple Employers), or `lgcyTri` (TRI – Transferred-In)
         */
        validation: (employment, isLastEmployment) => {
            if(!employment?.participation?.status?.isActive){
                console.warn('EmploymentTaskConfig memStask validation: employment.participation.status.isActive is undefined/null, data might be corrupted');
            }
            if(!employment?.participation?.isPendingClose){
                console.warn('EmploymentTaskConfig memStask validation: employment.participation.isPendingClose is undefined/null, data might be corrupted');
            }
            let enrolled = employment?.participation?.status?.isActive?.() && !employment?.participation?.isPendingClose?.();
            let hiredDateIsBeforeCutoff = moment(config.cutoff2023).valueOf() >= moment(employment.hiredDate).valueOf();
            const isLastEmploymentInParticipation = (typeof isLastEmployment === 'boolean' ? isLastEmployment : employment?.participation?.isLastEmploymentInParticipation?.());
            return isLastEmploymentInParticipation !== false && !hiredDateIsBeforeCutoff && enrolled && !employment.isFirstEmploymentInParticipation() 
                && !employment.events.find(x=> x.config.memSusEmailSent) && employment.events.find(x=> x.config.isMultipleEmployer);
        },
        form: <MemSustainedTask isEmployment/>,
    },
    infDed: {
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if:
         * - the participation status is active: `ACT` (Enrolled)
         * - and the participation is not "pending but not close": not: participation has a pending event `penDea` (Pending - Deceased) or `penTrm` (Pending - Terminated) or `penPnr` (Pending - Pensioner), and participation status is not close: not `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled)
         * - and the employment hired date (effective date of the event `hrd` "Hired" or `hrdRem` "Hired through remittance") is not before the 2023 cutoff (2023-01-01)
         * - and it's not the first employment in the participation
         * - and the employment does not have a `deductionSent` event: `actAutDed` (Employer notified to start deductions immediately)
         * - and the employment has a `isMultipleEmployer` event: `tro` (TRO – Transferred-Out) with status `trf` (Transferred Out), or `tsw` (SWO - Switched-Out) with status `trf` (Transferred Out), or `swi` (SWI - Switched-In), or `mer` (MER – Working at Multiple Employers), or `lgcyTri` (TRI – Transferred-In)
         */
        validation: (employment, isLastEmployment) => { 
            if(!employment?.participation?.status?.isActive){
                console.warn('EmploymentTaskConfig infDed validation: employment.participation.status.isActive is undefined/null, data might be corrupted');
            }
            if(!employment?.participation?.isPendingClose){
                console.warn('EmploymentTaskConfig infDed validation: employment.participation.isPendingClose is undefined/null, data might be corrupted');
            }
            let enrolled = employment?.participation?.status?.isActive?.() && !employment?.participation?.isPendingClose?.();
            let hiredDateIsBeforeCutoff = moment(config.cutoff2023).valueOf() >= moment(employment.hiredDate).valueOf();
            return !hiredDateIsBeforeCutoff && enrolled && !employment.isFirstEmploymentInParticipation() && !employment.events.find(x=> x.config.deductionSent) && employment.events.find(x=> x.config.isMultipleEmployer);
        },
        form: <YesNoQuestion isEmployment message='Have the employer and employee been informed of automatic deductions?' onYes={(employment, cmt) => {employment.addEvent({code: 'actAutDed', cmt: cmt})}}/>
    },
    ltd65: {
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if:
         * - participation is active: `ACT` (Enrolled)
         * - and employment status is `ltd` (Long Term Disability)
         * - and the member is over 65 or is turning 65 in 3 months
         * - and the participation does not have a `isNOP` event: not `a60Nop` (Age60: NOP - Active60 Declaration received: continue accruing) or `a60neg` (Age60: NOP - Member not entitled to Pension and work at age 60. Data confirmed by employer.) or `l60Nop` (LTD60: NOP - LTD60 Chose to continue LTD)
         */
        validation: (employment, isLastEmployment) => { 
            const isPPEnrolled = employment.participation.status.isActive();
            const isLTD = employment.status.isLtd();
            const isAge65InNext3Months = employment.participation.person.isTurningAgeInNextMonths(65, 3);
            const isOver65 = employment.participation.person.isOverAge(65);
            const hasNOP = employment.participation.hasNOP();

            return isPPEnrolled && isLTD && (isAge65InNext3Months || isOver65) && !hasNOP;
        },
        formalDescription: "LTD benefit likely to stop at age 65. Submit insurance letter terminating LTD benefits.",
    },
    expiredLeave: {
        /**
         * 
         * @param {Employment} employment 
         * @param {boolean | undefined} isLastEmployment Default: `true`
         * @returns {boolean} true if the employment is expired as of today:
         * - the event has an expiration
         * - and there is a participation join event, and the participation join event is not after this event.
         * - and the event's date + expiration weeks (for the jurisdiction) is past
         */
        validation: (employment, isLastEmployment) => {
            return employment.isExpiredAsOf(moment());
        },
        formalDescription: "Leave Expired: ASH will email data confirmation request.",
    },
}